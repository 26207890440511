const nosContactsContent = {
  section1: {
    mainTitle: `Nos Sieges`,
    block1: {
      title: `Montreal, Canada.`,
      subtitle: `Dropzik Digital Inc.`,
      description: `8815 ave. du Parc, Suite 402,Montréal, Québec, H2N 1Y7.`
    },
    block2: {
      title: `Alger, Algerie.`,
      subtitle: `Dropzik Digital Inc.`,
      description: `04, Rue Mohamed Abdou, <br />Bureau N18 El Mouradia, Alger`
    },
    block3: {
      title: `Paris, France.`,
      subtitle: `Dropzik Digital Inc.`,
      description: `Bientôt disponible`
    }
  },
  form: {
    name: {
      label: `Votre nom complet (requis)`,
      email: `Votre email complet (requis)`,
      subject: `Sujet`,
      message: `Votre message`,
    },
    submit: `Send`
  },
};

export default nosContactsContent;