import React from 'react'

interface FormFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
  containerClassName?: string;
}

/**
 * input form component combined with bootstreap css classes for ui
 */
export default function FormField({ name, onChange, type, className='', containerClassName = '', placeholder, label, }: FormFieldProps) {
  return (
    <div className={`form-group ${containerClassName}`}>
      <label className='fsz-18 text-white' htmlFor={name}>{label}</label>
      <input
        onChange={onChange}
        type={type}
        className={`form-control rounded-0 py-3 fsz-18 bg-darksliver h-auto ${className}`}
        id={name}
        name={name}
        placeholder={placeholder || label}
      />
    </div>
  )
}
