import React, { useState } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import nosContactsContent from '../config/contents/nos-contacts';
import Image from "../components/image";
import FormField from "../components/formInput";

import '../assets/css/nos-contacts.css';

const NosContactsPage: React.FC<PageProps<any>> = ({ data }) => {
  const { pageImage, site } = data;
  const { title, path, description } = site?.siteMetadata?.pages?.nosContacts;
  const [contactData, setContactData] = useState({
    loading: false,
    message: '',
    data: {
      fullname: '',
      email: '',
      message: '',
      subject: '',
    }
  });

  const onSubmit = async (evnt: React.FormEvent<HTMLFormElement>) => {
    evnt.preventDefault();
    if (typeof window !== 'undefined') {
      setContactData({
        ...contactData,
        loading: true
      });
      const $form = document?.querySelector('form');
      const formData = new FormData($form || undefined);
      try {
        const reqPromise = await fetch(
          'https://beta.dropzik.com/wp-json/dacontact/v1/new',
          {
            body: formData,
            method: 'POST',
          }
        );
        const response = await reqPromise.json();
        if (response.code === 'failed') {
          setContactData({
            ...contactData,
            message: response.message,
            loading: false
          });
        } else {
          setContactData({
            ...contactData,
            message: response.message,
            loading: false
          });
        }

        window?.scrollTo(0, 0)
      } catch (error) {
        setContactData({
          ...contactData,
          message: 'Ressayez svp',
          loading: false
        })
      }
    }
  }

  return (
    <Layout>
      <SEO title={title} description={description} path={path} image={pageImage?.childImageSharp.fluid.src} />

      <div className='bg-darkengrey position-relative'>

        <div className='blend-mode_screen bg__block'>
          <Image
            className='image__fluid hero__bg h-auto position-absolute right_0'
            src={pageImage?.childImageSharp?.fluid}
            width={pageImage?.childImageSharp?.fluid?.presentationWidth}
            height={pageImage?.childImageSharp?.fluid?.presentationHeight}
          />
        </div>

        <section className="d-block text-white">

          <div className="container position-relative">
            <div className="container__row row py-4">

              <h1 className="title my-5 pt-5 pb-0 pb-md-5">
                Nos <strong className="d-bold">Sieges</strong>
              </h1>

              <div className="d-flex flex-column flex-md-row w-100">

                <div className="d-block mr-0 mr-4 flex_1">
                  <h2 className="text-red d-bold fsz-20 mt-0 mb-3">{nosContactsContent.section1.block1.title}</h2>
                  <h3 className="h4 font-weight-light fsz-18 mb-4">{nosContactsContent.section1.block1.subtitle}</h3>
                  <p className='fsz-18'>{nosContactsContent.section1.block1.description}</p>
                </div>

                <div className="d-block mr-0 mr-4 flex_1">
                  <h2 className="text-red d-bold fsz-20 mt-0 mb-3">{nosContactsContent.section1.block2.title}</h2>
                  <h3 className="h4 font-weight-light fsz-18 mb-4">{nosContactsContent.section1.block2.subtitle}</h3>
                  <p className='fsz-18' dangerouslySetInnerHTML={{__html: nosContactsContent.section1.block2.description}}/>
                </div>

                <div className="d-block flex_1">
                  <h2 className="text-red d-bold fsz-20 mt-0 mb-3">{nosContactsContent.section1.block3.title}</h2>
                  <h3 className="h4 font-weight-light fsz-18 mb-4">{nosContactsContent.section1.block3.subtitle}</h3>
                  <p className='fsz-18'>{nosContactsContent.section1.block3.description}</p>
                </div>

              </div>

            </div>
          </div>

        </section>

        <div className="container position-relative my-0 my-md-5 pt-0 pt-md-5">
          <div className="container__row row pt-0 pt-md-5">

            <form action="/" className="w-100 my-3 my-md-5" onSubmit={onSubmit}>

              {
                contactData.message && (
                  <div className="alert alert-info rounded-0 btn__gradient text-white">{contactData.message}</div>
                )
              }

              <FormField containerClassName='mb-5' name='fullname' label='Votre nom complet (requis)' />
              <FormField containerClassName='mb-5 mt-n2' name='email' label='Votre Email (requis)' />
              <FormField containerClassName='mb-5 mt-n2' name='subject' label='Sujet (requis)' />
              <div className="form-group mb-5 mt-n2">
                <label htmlFor="message" className='text-white fsz-18'>Votre Message</label>
                <textarea name='message' className="form-control rounded-0 py-3 bg-darksliver fsz-18" id="message" rows={10}></textarea>
              </div>
              <div className="form-group mt-n2 mb-0">
                <button disabled={contactData.loading} type='submit' className='btn-style px-5 btn border-0 text-white text-decoration-none text-uppercase btn__gradient py-2 d-bold'>
                  envoyer
                </button>
              </div>

            </form>

          </div>
        </div>

      </div>

    </Layout>
  );
}

export const query = graphql`
  query ContactsQuery {
    site {
      siteMetadata {
        lang
        pages {
          nosContacts {
            title
            path
            description
          }
        }
      }
    }
    pageImage: file(relativePath: { eq: "contacts/bg-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
          presentationHeight
          presentationWidth
        }
      }
    }
  }
`;

export default NosContactsPage;
